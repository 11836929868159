<template>
  <section class="hero is-fullheight">
    <modal-add-product-service
      v-if="modalAddProductServiceActive"
      :active="modalAddProductServiceActive"
      @close-modal="modalAddProductServiceActive = false"
      :addProduct="addProduct"
    />
    <div class="hero-body is-align-items-flex-start">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <h1 class="title_view">
              Productos
            </h1>
          </div>
          <div class="column is-12">
            <div class="columns is-multiline is-justify-content-flex-end">
              <div class="column is-2">
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="orderBy" name="orderBy" @change="changeFilterType">
                      <option selected disabled :value="null">Ordenar por</option>
                      <option v-for="item in orderByOptions" :key="item.id" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <span v-show="vverrors.has('personType')" class="help is-danger">{{ vverrors.first('personType') }}</span>
                </div>
              </div>
              <div class="column is-3">
                <input
                  class="input is-fullwidth"
                  type="text"
                  placeholder="Buscador"
                  v-model="search"
                  @change="searchProduct"
                >
              </div>
              <div class="column is-2">
                <button class="button btn_aqua is-fullwidth">
                  Descargar
                </button>
              </div>
              <div class="column is-2" @click="modalAddProductServiceActive = true">
                <button class="button btn_violet is-fullwidth">
                  Agregar
                </button>
              </div>
            </div>
          </div>
          <div class="column is-12 is_grid pb-0">
            <div class="table-container">
              <BaseTable
                :columns="columnsData"
                :data="productList"
                :loading=loading
              >
                <div slot="status" slot-scope="scope">
                  <p
                    class="text_status has-text-weight-medium"
                    :class="[ scope.row.product_status === 'Pendiente' ? 'has_warning' :
                    scope.row.product_status === 'Aceptado' ? 'has_success' : 'has_danger' ]"
                  >
                    {{ scope.row.product_status}}
                  </p>
                </div>
              </BaseTable>
            </div>
          </div>
          <div class="column is-12 pt-0">
            <div class="pagination_container">
              <BasePagination
                :page="page"
                :pages="parseInt(pages)"
                :action="paginator"
                :loading="loading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'ProductsMain',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination'),
    ModalAddProductService: () => import('@/components/Products/ModalAddProductService.vue')
  },
  data () {
    return {
      loading: true,
      modalAddProductServiceActive: false,
      page: 1,
      pages: 1,
      limit: 10,
      type: 'T',
      search: '',
      orderBy: null,
      orderByOptions: [
        {
          id: 'T',
          name: 'Todos'
        },
        {
          id: 2,
          name: 'Servicios'
        },
        {
          id: 1,
          name: 'Productos'
        }
      ],
      columnsData: [
        { id: 'id', header: 'ID' },
        {
          id: 'product_name',
          header: 'Producto/servicio',
          link: true,
          type: 'link',
          class: 'link',
          route: (row) => ({ name: 'detalle-producto', params: { productId: row.id } })
        },
        { id: 'name', header: 'Usuario', accessor: (row) => !row.is_admin ? `${row.name} ${row.last_name} ${row.second_last_name}` : 'Barta' },
        { id: 'created_at', header: 'Fecha de creación', class: 'has-text-centered', accessor: (row) => moment(row.created_at).format('DD/MMM/YYYY') },
        { id: 'product_status', name: 'status', header: 'Estatus', class: 'has-text-centered' }
      ],
      productList: []
    }
  },
  methods: {
    ...mapActions(['getProductList', 'createProduct']),
    async getList (page, limit, type, search) {
      this.loading = true
      const resp = await this.getProductList({ page, limit, type, search })
      if (resp.success) {
        this.productList = resp.payload
        this.pages = resp.totalPages
      }
      this.loading = false
    },
    searchProduct () {
      this.page = 1
      this.getList(this.page, this.limit, this.type, this.search)
    },
    changeFilterType () {
      this.type = this.orderBy
      this.page = 1
      this.getList(this.page, this.limit, this.type, this.search)
    },
    paginator (page) {
      this.page = page
      this.getList(this.page, this.limit, this.type, this.search)
    },
    async addProduct (data) {
      const resp = await this.createProduct(data)
      if (resp.success) {
        this.modalAddProductServiceActive = false
        this.page = 1
        this.search = ''
        this.getList(this.page, this.limit, this.type, this.search)
      }
    }
  },
  beforeMount () {
    this.getList(this.page, this.limit, this.type, this.search)
  }
}
</script>

<style lang="scss" scoped>
  .input {
    border-radius: 3px;
    background-color: #fff;
    border: none;
  }
  .button {
    height: 3em;
  }
  .text_status {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    letter-spacing: 0.13px;
  }
  .select select {
    border: solid 1px #fff;
    background-color: #fff;
    border-radius: 8px;
    font-size: 14px;
    height: 3em;
    color: #5d5d5d;
  }
  .select:not(.is-multiple):not(.is-loading)::after {
    margin-top: -0.3em;
    border-color: #5dca96;
  }
</style>
